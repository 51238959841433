import { styled } from '@mui/system';
import Box from '@mui/material/Box';

const BnWContent = styled(Box)({
	// marginTop: 'auto',
	// marginBottom: '50px',
	// margin: 'auto 64px 50px',
	width: '100%',
	// img: { width: '100%' },
	position: 'relative',
});

export default BnWContent;
