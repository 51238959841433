import * as React from 'react';
import PropTypes from 'prop-types';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import PreviewCompatibleImage from '../components/PreviewCompatibleImage';
import Grid from '@mui/material/Grid';

const FeatureGrid = ({ gridItems, gridWidth }) => (
	<>
		{gridItems.map((item) => (
			<Grid item px={4} sm={gridWidth} key={item.heading}>
				{/* <article key={item.text}> */}
				<PreviewCompatibleImage imageInfo={item} />
				<Typography variant='h4' className='title'>
					{item.heading}
				</Typography>
				<Typography
					variant='div'
					className='description'
					dangerouslySetInnerHTML={{ __html: item.text.html }}
				/>
				{/* </article> */}
			</Grid>
		))}
	</>
);

FeatureGrid.propTypes = {
	gridItems: PropTypes.arrayOf(
		PropTypes.shape({
			image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
			text: PropTypes.string,
			heading: PropTypes.string,
		})
	),
};

export default FeatureGrid;
